'use strict'
const name = 'BgSuperZoomIn'
const properties = {
    hideOnStart: false,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement} element DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(element, duration, delay, params = {}) {
        const sequence = factory.sequence(params)

        sequence.add(
            factory.animate('BaseBgZoom', element, duration, delay, {
                baseScale: 1.2,
                in: {
                    start: 0,
                    end: 1,
                    scale: 2.2,
                    ease: 'sine.in'
                }
            })
        )

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
