'use strict'
const {isNumber} = require('../../../../utils/definitionsUtils')

const name = 'BaseBgFade'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        baseOpacity: {
            type: 'number',
            min: 0,
            default: 1
        },
        in: {
            type: 'object',
            properties: {
                start: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                end: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                ease: {
                    type: 'string'
                },
                opacity: {
                    type: 'number',
                    min: 0,
                    max: 1
                }
            }
        },
        out: {
            type: 'object',
            properties: {
                start: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                end: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                ease: {
                    type: 'string'
                },
                opacity: {
                    type: 'number',
                    min: 0,
                    max: 1
                }
            }
        }
    }
}

function register({factory}) {
    /**
     * Fade balata media element on scroll
     * @param {HTMLElement} element DOM element to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @returns {TimelineMax}
     */
    function animation(element, duration, delay, {
        baseOpacity = properties.schema.baseOpacity.default,
        in: {
            start: in_start,
            end: in_end,
            ease: in_ease,
            opacity: in_opacity
        } = {},
        out: {
            start: out_start,
            end: out_end,
            ease: out_ease,
            opacity: out_opacity
        } = {},
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        const hasInAnimation = isNumber(in_start) && isNumber(in_end)
        const hasOutAnimation = isNumber(out_start) && isNumber(out_end)

        // set sequence length to full duration
        sequence.add(factory.animate('BaseNone', element, duration, delay), 0)

        // Place in animation on timeline
        if (hasInAnimation) {
            if (in_end < in_start) {
                console.warn(`santa-animations: ${name}: "in" end value ${in_end} must be larger than "in" start value ${in_start}`)
            }
            const _duration = duration * Math.max(in_end - in_start, 0)
            const _delay = delay + duration * in_start
            sequence.add(factory.animate('BaseFade', element, _duration, _delay, {
                from: {opacity: in_opacity},
                to: {opacity: baseOpacity},
                ease: in_ease,
                force3D: true,
                immediateRender: !hasOutAnimation || in_start < out_start
            }), 0)
        }

        // place out animation on timeline
        if (hasOutAnimation) {
            if (out_end < out_start) {
                console.warn(`santa-animations: ${name}: "out" end value ${out_end} must be larger than "out" start value ${out_start}`)
            }
            const _duration = duration * Math.max(out_end - out_start, 0)
            const _delay = delay + duration * out_start
            sequence.add(factory.animate('BaseFade', element, _duration, _delay, {
                from: {opacity: baseOpacity},
                to: {opacity: out_opacity},
                ease: out_ease,
                force3D: true,
                immediateRender: !hasInAnimation || out_start < in_start
            }), 0)
        }


        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
