'use strict'
const name = 'BgPanRight'
const properties = {
    hideOnStart: false,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        speedFactor: {
            type: 'number',
            min: 0,
            default: 0.2
        }
    }
}

function register({engine, factory}) {
    /**
     * @param {HTMLElement} element DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(element, duration, delay, {
        speedFactor = properties.schema.speedFactor.default,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        const {width} = engine.getBoundingRect(element)
        sequence.add([
            factory.animate('BaseBgZoom', element, duration, delay, {
                in: {
                    start: 0,
                    end: 0,
                    scale: 1 + speedFactor,
                    ease: 'none'
                }
            }),
            factory.animate('BaseBgPositionX', element, duration, delay, {
                start: 0,
                end: 1,
                from: -width * speedFactor / 2,
                to: width * speedFactor / 2,
                ease: 'none'
            })
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
